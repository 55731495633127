import './kendo.web.js';
import './kendo.dataviz.js';
import './kendo.mobile.js';
import './kendo.drawing.js';
import './kendo.dom.js';

"bundle all";
var kendo$1 = kendo;

export { kendo$1 as default };
